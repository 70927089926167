$redColor: #CC0001;
$whiteColor: #FFFFFF;
$blackColor: black;

@media (max-width: 1024px) {

  .web-hidden {
    display: inherit;
  }

  .mobile-hidden {
    display: none;
  }

  body > div > section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .logos-section {
    height: 7rem;
  }

  header.site-header {
    height: 90vh;
    overflow: visible !important;
    z-index: 2;
    .parallax-wrapper {
      height: 90vh;
    }
    .heading-content.child-center-v {
      top: 35%;
      transform: translateY(-35%);
    }
  }


  .col-sm-2.col-xs-4.logo, .col-xs-8.toggle-menu.child-center-v {
    width: 50%;
    .social-logo {
      height: 18px !important;
      width: auto;
    }
  }

  header.site-header .site-navigation-bar .toggle-menu .navTrigger {
    width: 28px;
    height: 15px;
    top: -6px;
    i:nth-child(2) {
      margin: 8px 0;
    }
  }

  .web-hidden.logos-section-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    z-index: 1;
    gap: 10px;
    .logos-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;
      gap: 10px;
      height: unset;
      div {
        display: flex;
      }
    }
  }

  /* Style the tab */
  .tab {
    overflow: hidden;
  }

  /* Style the buttons that are used to open the tab content */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    color: $redColor;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
  }

  .tabcontent {
    animation: fadeEffect 1s; /* Fading effect takes 1 second */
  }
  
  /* Go from zero to full opacity */
  @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }


  header > .parallax-wrapper > .container {
    width: 100%;
  }

  .container {
    padding: 8%;
  }

  .heading-content {
    h1 {
      line-height: 1;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.5;
      width: 100%;
    }
  }

  h1 {
    line-height: 1;
    font-size: 2.571rem;
  }
  
  header.site-header {
    .site-navigation-bar {
      background: black;
    }
  }

  .container {
    padding: 8%;
  }


  .home-section-1 {
    padding: 0 4% !important;
    h1 {
      margin-bottom: 10%;
    }
  }

  .container.home-section2 {
    h1 {
      font-size: 2.571rem;
    }
    p {
      width: 93.5%;
    }
  }

  .container.home-section2, .container.home-section4 {
    padding: 8%;
  }

  .container.home-section3 {
    h1 {
      margin: 0px 2%;
    }
  }

  .home-section3-container {
    flex-direction: column;
    .home-section3-container-item {
      flex: 1;
      flex-direction: column;
      .section-3-cart {
        height: 100%;
        flex: 1 1 calc(100%);
      }
    }
  }

  .container.home-section4 {
    h1 {
      line-height: 1;
      font-size: 2.571rem;
    }
    .row {
      margin-top: 10%;
    }

    .row .col-md-4 {
      height: unset;
    }
  }

  .container.home-section5 {
    padding: 8%;
    .learn-more-button {
      margin-top: 1rem;
    }
    .home-section5-card {
      margin-bottom: 0;
      margin-top: 5%;
    }

    .col-xs-12.col-md-6:nth-child(1) {
      margin-top: 8%;
    }

  }

  .ai-section-1, .home-section-1 {
    .container {
      padding: 6%;
    }
    p {
      font-size: 20px;
      line-height: 31px;
    }
  }

  .container.ai-section2 {
    padding: 6%;
    padding-bottom: 35%;
    padding-top: 17%;
    & > div {
      margin-right: 0;
    }
    h1 {
      font-size: 2.571rem;
    }
    p {
      width: 100%;
    }
  }

  #ai-section3 {
    .container.ai-section3 .learn-more-button {
      margin-right: 0;
    }
  }

  .container.ai-section3 {
    padding: 6%;
    .next-gen-section, .learn-more-button-container {
      display: none;
    }
    h1 {
      margin: 7% 0;
    }
    .next-gen-section-mobile {
      display: block;
      padding: 2%;
      border-radius: 10px;
      background-color: #00000066;
      .tab {
        display: flex;
        padding: 5%;
        button {
          flex: 1;
          text-align: left;
          font-size: 1.286rem;
          padding-left: 0;
          border-bottom: 1px solid #FFFFFF66;
          color: $whiteColor;
        }
        button:nth-child(2) {
          padding-right: 0;
        }
        button.active {
          color: $redColor;
          border-bottom: 1px solid #FFFFFF;
        }
      }
      .learn-more-button-container {
        display: block;
      }
      .learn-more-button {
        width: 100%;
        margin-top: 7%;
      }
    }
  }

  .container.container.ai-section5 {
    padding: 4%;

    & > h1 {
      margin: 2rem;
    }
    
    .next-gen-container {
      padding: 8%;
    }

    .next-gen-section {
      display: block;
    }

    .next-gen-body {
      padding-right: 0%;
      img {
        display: block;
      }
    }

    .next-gen-section {
      & > .learn-more-button-container {
        display: block;
        button {
          width: 93%;
          float: unset;
        }
      }
    }

    .geist-info-container{
      display: none;
    }

    #geist-img1 {
      max-width: calc(100% + 30px);
      margin-left: -15px;
    }
    #geist-img2 {
      max-width: calc(100% + 22px);
      margin-left: -11px;
    }
    #geist-img3 {
      max-width: calc(100% + 24px);
      margin-left: -12px;
    }
    
    .learn-more-button {
      margin-top: 4%;
      margin-bottom: 4%;
    }
  }

  .container.ai-section4 {
    padding: 8%;
    h1 {
      font-size: 2.571rem;
      width: 100vw;
      margin-left: -10%;
    }
    p {
      font-size: 1.429rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    .media-section {
      row-gap: 20px;
      margin-top: 0;
      margin-bottom: 2rem;
      justify-content: center;
    }
    .media-logos-row {
      margin-top: 2rem;
    }
    .learn-more-button {
      width: 100%;
    }
  }

  .studio-page {
    #navMenu {
      li > a {
        color: $whiteColor;
      }
    }
    .logos-section-container {
      display: none;
    }
  }

  .studio-page-content {

    .container {
      padding: 3%;
    }

    
  }

  .studio-section-1 {
    top:25%;
    padding: 0 4% !important;
    .container {
      padding: 0;
    }
    .heading-title, .body-content {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .container.studio-section2, .container.studio-section3, .container.studio-section4 {
    h1 {
      font-size: 2.571rem;
      margin: 6% 0;
    }
  }

  .container.studio-section2 {
    padding: 0;
    .unfair-advantages-section {
      flex-direction: column;
    }
    h1 {
      font-size: 2.571rem;
      margin: 6% 0;
    }
    .unfair-advantages-container {
      padding: 2rem;
    }
  }

  .studio-section3-container {
    flex-direction: column;
  }

  .studio-section4, .studio-section5 {
    .studio-section4-container-items {
      flex-direction: column;
    }
  }

  .container.studio-section4, .container.studio-section5 {
    .studio-section4-container-items {
      .studio-section4-container-item {
        padding: 4%;
      }
    }
  }

  .container.studio-section4, .container.studio-section5, .container.studio-section6 {
    padding: 4%;
  }

  .container.studio-section5 {
    h1 {
      margin: 3%;
    }
    .studio-section4-container-items {
      gap: 1rem;
      margin: 5% 0;
    }
  }

  .container.studio-section4 .studio-section4-container-items {
    gap: 1rem;
    margin: 5% 0;
  }

  .container.studio-section6 {
    h1 {
      margin-bottom: 7%;
    }
    .studio-section6-container-items {
      flex-direction: column;
      .studio-section6-container-item {
        padding: 4%;  
        h3 {
          font-size: 1.429rem;
        }
        p, strong {
          font-size: 16px;
        }
        .studio-cart-body:nth-child {
          margin-top: 5%;
        }
        
      }
    }

    .studio-section6-second-container {
      .studio-cart-body {
        padding-top: 5%;
        border-top: 1px solid #FFFFFF66;
      }
    }
    
  }

  header.site-header.about-page {
    height: 100vh;
    .parallax-wrapper {
      height: 100vh;
    }
  }

  .container.about-section1 {
    padding: 2%;
    padding-top: 0;
    h1 {
      font-size: 2.571rem;
      line-height: 3rem;
    }
    p {
      width: 100%;
      font-size: 1.429rem;
    }
  }


  .container.about-section2, .container.about-section3 {
    padding: 6%;
    h1 {
      margin: 8% 0;
    }
    h3 {
      font-size: 23px;
    }
    p, strong {
      font-size: 16px !important;
      line-height: 22px;
    }
    .leadership-container {
      width: 100%;
      flex-direction: column;

    }
  }

  section.site-section {
    padding-bottom: 30px;
  }

  .contact-section {
    padding-bottom: 1rem;
  }

  .form-container form button {
    width: 100%;
    margin: 0;
  }

  .form-container form .form-row {
    margin: 0;
  }


  .site-footer {
    & > div {
      
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      .social-logo {
        height: 20px !important;
      }
    }
  }
}