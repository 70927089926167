* {
  box-sizing: border-box;
}

@media (max-width: 480px) {
  html {
    font-size: 13px; /* Optimized for small smartphones */
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  html {
    font-size: 14px; /* Slightly larger for larger smartphones and small tablets */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  html {
    font-size: 16px; /* Standard base font size for tablets */
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  html {
    font-size: 16px; /* Slightly larger for standard desktops and laptops */
  }
}

@media (min-width: 1441px) {
  html {
    font-size: 16px; /* Optimized for large desktop screens and 4K monitors */
  }
}

body {
  font-size: 1rem; /* Scales with html's font size */
  background-color: black;
}

body > div > section {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@font-face {
  font-family: Eurostile-Extended;
  src: url('../../fonts/euro-stile/EurostileExtendedBlack2.ttf');
}

$redColor: #CC0001;
$whiteColor: #FFFFFF;
$blackColor: black;

.web-hidden {
  display: none;
}

.mobile-hidden {
  display: inherit;
}

header.site-header .site-navigation-bar .logo a {
  font-family: Eurostile-Extended;
  font-weight: 900;
  font-size: 26px;
}

header > .parallax-wrapper > .container {
  width: 90%;
}

header.site-header .container{
  height: unset;
}

.heading-content {
  h1 {
    font-size: 4.3rem;
    line-height: 1.8;
    font-weight: 700;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    width: 55%;
  }
}

h1, h2, h3, h4 {
  font-weight: 500;
}

.red-font {
  color: $redColor;
}

#navMenu {
  ul {
    font-weight: bolder;
  }
}

header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li {
  float: left;
  margin-right: 100px;
}

.logos-section-container {
  background-color: #181818;
  padding-left: 3%;
  padding-right: 4%;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  & > div {
    padding: 0;
  }
}



.logos-section {
  margin: 0;
  height: 128px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

body {
  font-family: 'IBM Plex Sans';
}

.learn-more-button {
  width: 100%;
  background-color: #3366ff;
  color: $whiteColor;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
      background-color: #0044cc;
  }
}

h1, h2, p, li {
  font-family: 'IBM Plex Sans';
  color: $whiteColor;
}

h1 {
  font-family: 'IBM Plex Sans';
  text-transform: none;
  line-height: 1.8;
}

p {
  text-transform: none;
}

p, li {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}


.home-section-1 {
  padding-left: 7%;
  text-align: left;
}

.container.home-section-image-background {
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image within the div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100%;
}


// ------------- START HOME PAGE ------------------------

#home-section2 {
  background-image: url('/images/home/home_banner2.jpeg');
}

.container.home-section2 {
  padding: 8% 15%;
  
  text-align: left;
  h1 {
    font-size: 4rem;
    line-height: 1;
    font-weight: 500;
  }
  p {
    margin-top: 1.25rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    width: 77.8%;
  }
}

.section-3-cart {
  background: #00000066;
  padding: 16px;
  .cart-header {
    border-bottom: 2px solid $whiteColor;
    padding-bottom: 6%;
    text-align: center;
    margin-bottom: 6%;
    h3 {
      color: $redColor;
      font-weight: bolder;
      font-size: 1.5rem;
    }
  }
  ul {
    list-style-image: url('/images/icons/Icons-List.svg');
    line-height: 2.2rem;
    // Nested media query
    @media (max-width: 768px) {
      column-count: 2;
    }

    li {
      font-size: 1rem;
      margin-left: 20px;
    }
  }
  ul.list-double-column {
    column-count: 2; /* Allow the list to split into two columns if needed */
  }
}

#home-section3 {
  background-image: url('/images/home/home_banner3.jpeg');
}
.container.home-section3 {
  padding: 6%;
  
  h1 {
    text-align: center;
    line-height: 1.2;
    margin: 0 12%;
  }
  .home-section3-container {
    display: flex;
    margin-top: 5%;
    justify-content: space-between;
    gap: 16px;
    align-items: stretch;
    .home-section3-container-item {
      flex: 1;
      .cart-header {
        border-bottom: 2px solid #FFFFFF33;
      }
      .section-3-cart {
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}

#home-section4 {
  background-image: url('/images/home/home_banner4.jpeg');
}
.container.home-section4 {
  padding: 6% 12%;
  color: $whiteColor;
  h1 {
    text-align: center;
    font-size: 4rem;
  }
  .row {
    margin-top: 1%;
    .col-md-4 {
      height: 175px;
      display: flex;
    }
  }
  .home-section4-cart {
    margin-bottom: 10%;
    .title-container {
      margin-bottom: 4%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 700;
      img {
        margin-right: 2%;
      }
    }
    .text-container {
      font-size: 1rem;
      line-height: 1.625rem;
      padding-right: 11%;
      font-weight: 400;
    }
  }
}

#home-section5 {
  background-image: url('/images/home/home_banner5.jpeg');
}
.container.home-section5 {
  padding: 6% 12%;
  
  color: $whiteColor;
  font-size: 1rem;
  h1 {
    text-align: center;
  }
  .row {
    @media (min-width: 768px) {
      display: flex;
      padding: 0 7%;
    }
  }

  .home-section5-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #00000066;
    padding: 11%;
    margin-bottom: 10%;
    margin-top: 10%;
    border-radius: 10px;
    li {
      line-height: 2;
    }
    .body-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1.625rem;
    }
    .title-container{
      color: $redColor;
      font-size: 1.5rem;
      border-bottom: 1px solid #FFFFFF66;
      padding-bottom: 6%;
      margin-bottom: 6%;
    }
    .card-sub-title {
      color: #FFFFFFB2;
      margin-bottom: 3rem;
    }
    .card-body {
      div, li {
        margin-bottom: 10px;
      }
      .p-list-item {
        margin-left: 15px;
        display: list-item;
        width: 90%;
      }
    }
  }
}

// ------------- END HOME PAGE ------------------------


// ------------- START AI PAGE ------------------------

.ai-section-1 {
  h1 {
    width: 85%;
    line-height: 4.75rem;
    margin-bottom: 2%;
  }
  p {
    width: 52%;
  }

}

#ai-section2 {
  background-image: url('/images/ai/section2.jpeg');
}

#ai-section3 {
  background-image: url('/images/ai/section3.jpeg');
  .container.ai-section3 .learn-more-button {
    margin-right: 1rem;
  }
}

#ai-section4 {
  background-image: url('/images/ai/section4.jpeg');
}

#ai-section5 {
  background-image: url('/images/ai/section3.jpeg');
}

.container.ai-section2 {
  padding: 16%;
  text-align: left;
  div {
    margin-right: 26%;
  }
  h1 {
    font-size: 4rem;
    line-height: 1;
  }
  p {
    margin-top: 1.25rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    width: 71%;
  }
}

.container.ai-section3 {
  padding: 6% 12%;
  text-align: center;
  color: $whiteColor;
  .next-gen-section-mobile {
    display: none;
  }
  .next-gen-section {
    display: flex;
  }
  .next-gen-container {
    background-color: #00000066;
    border-radius: 10px;
    padding: 2rem 3.5rem;
    flex: 1;
    margin: 1rem;
    .next-gen-title {
      color: $redColor;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #FFFFFF66;
      font-size: 1.5rem;
      text-align: left;
      h3 {
        font-size: 1.5rem;
      }
    }
  }

  .next-gen-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1.5rem;
    padding-right: 8.7%;
    strong {
      font-size: 1.125rem;
      line-height: 1.938rem;
    }
    li, p {
      font-size: 1rem;
      line-height: 1.625rem;
    }
    ul {
      list-style: inherit;
      margin-left: 1rem;
      li {
        margin-bottom: 1rem;
      }
    }
    
  }

  .next-gen-container:last-child {
    .next-gen-body{
      padding-right: 8.4%;
      & > div:last-child {
        margin-top: 26%;
      }
    }
  }

  .learn-more-button {
    width: 20%;
    float: right;
    height: 50px;
    margin-top: 2%;
  }
  
}

.container.ai-section5 {
  padding: 6% 16%;
  flex: 1 1 calc(50% - 16px);
  .next-gen-container, .geist-info-container {
    flex: 1 1 calc(50%);
    .next-gen-body {
      padding-right: 0%;
      img {
        display: none;
      }
    }
  }

  .next-gen-section {
    & > .learn-more-button-container {
      display: none;
    }
  }

  .geist-info-container {
    display: flex;
    flex-direction: column;
    .geist-images-container {
      flex: 7;
      position: relative;

      .image {
        position: absolute; /* Allows overlapping positioning */
        width: 100%; /* Adjust image sizes */
        height: auto;
      }

      .image-back {
        z-index: 1; /* Background image */
        top: 10px; /* Offset positioning */
        height: 100% !important;
      }

      .image-middle {
        z-index: 2; /* Middle image */
        width: 90%;
        top: 25%;
        right: -14%;
      }

      .image-front {
        z-index: 3; /* Foreground image */
        width: 73%;
        top: 58%;
        left: 37%;
      }
    }
    .learn-more-button-container {
      flex: 1;
      padding-left: 0.9rem;
      padding-right: 1rem;
      .learn-more-button {
        margin-top: 5%;
        width: 100%;
      }
    }
  }
}

.container.ai-section4 {
  padding: 8% 16%;
  padding-bottom: 16%;
  text-align: left;
  h1 {
    font-size: 4rem;
    line-height: 1;
    text-align: center;
  }
  p {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-weight: 400;
    text-align: left;
    font-size: 2rem;
    line-height: 2.625rem;
  }
  .media-logos-row {
    margin: 0;
    margin-top: 4rem;
  }

  .learn-more-button {
    width: 24%;
    float: right;
    height: 50px;
    border-radius: 8px;
  }

  .media-logos-section-container {
    margin: 0;
  }

  .media-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; // Adjust this depending on your spacing preference

    // Default for desktop: display all elements in one row
    .media-logo {
      width: 5.313rem;
      display: flex;
      justify-content: center;
    }

    // Media query for mobile: display elements in 3 rows
    @media (max-width: 768px) { // Adjust the breakpoint as needed
      .media-logo {
        flex: 0 0 33.33%; // 33.33% width for 3 items per row
      }
    }
  }
}

// ------------- END AI PAGE ------------------------

// ------------- START STUDIO PAGE ------------------------

#studio-section2 {
  background-image: url('/images/studio/section2.jpg');
}

#studio-section3 {
  background-image: url('/images/studio/section3.jpeg');
}

#studio-section4 {
  background-image: url('/images/studio/section4.jpeg');
}

#studio-section5 {
  background-image: url('/images/studio/section5.jpg');
}

#studio-section6 {
  background-image: url('/images/studio/section6.jpeg');
}



.studio-page {
  #navMenu {
    li > a {
      color: $blackColor;
    }
  }

}

.studio-page, .studio-page-content {
  h1, li, p {
    color: $blackColor;
  }
}

.studio-page-content {
  .learn-more-button {
    background-color: #404040;
    padding: 15px;
  }

  .logos-section-container {
    display: none;
  }
}

.studio-section-1 {
  text-align: left;
  padding-left: 7%;
  .container {
    padding-right: 22%;
  }
  h1 {
    line-height: 1;
    text-align: left;
  }
  .body-content {
    margin-top: 2rem;
    padding: 2%;
    background-color: #FFFFFF4D;
    border-radius: 10px;
    width: 96%;
    p {
      width: 100%;
    }
  }
}

.container.studio-section2 {
  padding: 8% 16%;
  
  h1 {
    font-size: 4rem;
    line-height: 1;
    text-align: center;
    margin-bottom: 8%;
  }
  .unfair-advantages-section {
    display: flex;
  }
  .unfair-advantages-container {
    background-color: #FFFFFF4D;;
    border-radius: 10px;
    padding: 3rem;
    flex: 1;
    margin: 1rem;
    .unfair-advantages-title {
      color: $blackColor;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #FFFFFF66;
      h3 {
        font-weight: 700;
        line-height: 1.688rem;
      }
    }
    .unfair-advantages-body {
      margin-right: 2%;
      li, p {
        font-size: 1rem;
        line-height: 1.5;
      }
      ul {
        list-style: inherit;
        margin-left: 1rem;
        li {
          margin-bottom: 1rem;
        }
      }
    }
    
  }
}

.container.studio-section3 {
  padding: 4% 4% 8%;
  text-align: left;
  h1 {
    text-align: center;
  }
  .studio-section3-container {
    display: flex;
    margin-top: 4%;
    justify-content: space-between;
    gap: 16px;
    align-items: stretch;
    ul {
      list-style-image: url('/images/icons/Icons-List-Black.svg');
    }
    .studio-section3-container-item {
      flex: 1;
      .section-3-cart {
        background: #FFFFFF66;
        height: 100%;
        border-radius: 8px;
        .cart-header {
          border-bottom: 2px solid #00000033;
          h3 {
            color: $blackColor;
          }
        }
      }
    }
  }
}

.container.studio-section4 {
  padding: 2% 16% 8%;
}

.container.studio-section4, .container.studio-section5 {
  text-align: center;
  .studio-section4-container-items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 3rem;
    margin-top: 2%;
    .studio-section4-container-item {
      flex: 1 1 calc(50% - 16px);
      border-radius: 10px;
      background-color: #FFFFFF80;
      text-align: left;
      padding: 2%;
      color: $blackColor;
      h3 {
        margin-bottom: 1rem;
        font-size: 1.714rem;
        line-height: 2.214rem;
        font-weight: 600;
      }
    }
  }
}

.container.studio-section5 {
  padding: 2% 16% 8%;
  padding-top: 0;
  .background-color {
    width: 100%;
    height: 100%;
    background-color: #ffffff70;
    position: absolute;
  }
  h3 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.625rem;
  }
  .studio-section4-container-item {
    height: 11.625rem;
  }
  .studio-section4-container-items {
    align-items: stretch;
  }
  .studio-cart-body {
    margin-right: 0%;
  }
}

#studio-section5 {
  h1.mobile-hidden {
    width: 90vw;
    margin: auto;
    text-align: center;
    padding-top: 2%;
  }
  .container.studio-section5 .studio-section4-container-item {
    height: 12.625rem;
  }
}

.container.studio-section6 {
  padding: 2% 15.5% 8%;
  text-align: center;
  .studio-section6-container-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: $whiteColor;
    .studio-section6-container-item {
      flex: 1 1 calc(25% - 32px);
      border-radius: 10px;
      background-color: #00000080;
      text-align: left;
      padding: 1rem;

      .studio-cart-body {
        & > div {
          display: flex;
          flex-direction: column;
          div {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      
      p {
        color: $whiteColor;
        line-height: 1.625rem;
        font-size: 16px;
      }
      .studio-cart-header { margin-bottom: 1rem; }
    }
  }
  .studio-section6-second-container {
    h3, h4 {
      text-align: center;
    }
    h3 {
      margin-bottom: 0.8rem;
    }
    h4 {
      font-weight: 200;
    }
    .studio-cart-header {
      margin-bottom: 2rem;
    }
  }
}

// ------------- END STUDIO PAGE ------------------------

// ------------- START ABOUT PAGE ------------------------

.about-page {
  .logos-section-container {
    display: none;
  }
}

.container.about-section1 {
  padding: 8% 9% 4%;
  text-align: left;
  h1 {
    color: $redColor;
    line-height: 4.75rem;
    font-size: 4.313rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }
  p {
    width: 59.7%;
    font-size: 1.25rem;
    line-height: 1.938rem;
  }
}

#about-section2 {
  background-image: url('/images/home/home_banner2.jpeg');
}

#about-section3 {
  background-image: url('/images/home/home_banner4.jpeg');
}

.container.about-section2, .container.about-section3  {
  padding: 2% 16% 8%;
  text-align: center;
  color: $whiteColor;
  .leadership-container {
    display: flex;
    width: 65%;
    margin: auto;
    gap: 3rem;
    margin-top: 1rem !important;
    h3 {
      font-size: 1.5rem;
      font-weight: 700;
    }
    & > div {
      flex: 1 1;
      .worker-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h3, h4 {
          text-align: center;
        }
        h3 {
          margin-bottom: 0.5rem;
        }
        p {
          text-align: left;  
          font-size: 14px !important;
          line-height: 22px;
          margin-bottom: 0px;
        }
        .media-logo {
          width: 100%;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          position: relative;
          border-radius: 10px;
          img {
            filter: grayscale(100%);
            width: 100%;
            height: 100% !important;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// ------------- END ABOUT PAGE ------------------------

.form-container {
  margin-top: -90px !important;
  padding: 0;
  border-radius: 8px;
  width: 100%;
  text-align: center;

  h1 {
      margin-bottom: 20px;
  }

  form {
      display: flex;
      flex-direction: column;
      text-align: left;

      label {
          font-size: 0.9em;
          color: #FFFFFF80;
          margin: 5px 0;
      }

      .form-row {
          display: flex;
          gap: 10px;
          margin-bottom: 15px;

          .input-group {
              display: flex;
              flex-direction: column;
              flex: 1;

              label {
                  margin-bottom: 5px;
                  color: #FFFFFF80;

              }
          }
      }

      input[type="text"],
      input[type="email"],
      textarea {
          background: #000000;
          border: 1px solid #555;
          padding: 10px;
          color: $whiteColor;
          border-radius: 5px;
          width: 100%;
          margin-bottom: 15px;

          &::placeholder {
              color: #aaa;
          }
      }

      textarea {
          resize: vertical;
          height: 100px;
      }

      button {
          margin-top: 20px;
          width: 38%;
          margin-left: 62%;
          height: 50px;
          background-color: #3366ff;
          color: $whiteColor;
          padding: 10px;
          border: none;
          border-radius: 5px;
          font-size: 1em;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
              background-color: #0044cc;
          }
      }
  }
}

//footer
.site-footer {
  background-color: black !important;
  border-top: 1px solid #262626;
  height: 12.313rem;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 90%;
    margin: auto;
  }
  .footer-text > p {
    color: #FFFFFF80;
  }
}