$redColor: #CC0001;
$whiteColor: #FFFFFF;
$blackColor: black;

@media (min-width: 1921px) {

  body > div > section > * {
    max-width: 1440px;
    margin-left: auto !important;
    margin-right: auto !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .logos-section-container {
    .logos-section > .social-logo {
      width: 140px;
    }
  }
  
  .container.home-section2 {
    padding: 8% 10%;
    p {
      width: 79.7%;
    }
  }

  .container.home-section3 {
    padding: 4%;
  }

  .container.home-section4 {
    padding: 6% 7%;
  }

  .container.home-section5 {
    padding: 6% 7%;
  }


  .container.ai-section2 {
    padding: 10%;
  }

  .container.ai-section3 {
    padding: 6% 8%;
  }

  .container.ai-section4 {
    padding: 6% 10%;
  }

  .container.ai-section5 {
    padding: 6% 9%;
  }

  .studio-section-1 .container {
    padding-right: 16%;
  }

  .container.studio-section2 {
    padding: 4% 10.1% 8%;
  }

  .container.studio-section4 {
    padding: 2% 10% 8%;
  }

  .container.studio-section5 {
    padding: 2% 8.5% 8%;
    padding-top: 0px;
  }

  .container.studio-section6 {
    padding: 2% 10% 8%;
    text-align: center;
  }

  .container.about-section1 {
    padding: 8% 20% 4%;
    p {
      width: 62%;
    }
  }

  .container.about-section2, .container.about-section3 {
    padding: 2% 11% 8%;
  }
}